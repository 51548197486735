import * as React from "react";
// import "./button.scss"
import scrollTo from "gatsby-plugin-smoothscroll";

export interface ButtonProps {
  variant?: "green" | "blue" | "transparent";
  scrollTarget?: string;
  size?: "regular" | "large" | "small";
  text?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  mailLink?: boolean;
  className?: string;
  link?: string;
}

/**
 * Children has priority over the text parameter.
 */
const Button = ({
  text,
  link,
  variant,
  scrollTarget,
  children,
  fullWidth,
  size,
  style,
  className,
}: ButtonProps) => {
  return (
    <>
      <a
        style={{ ...style, textDecoration: "none" }}
        className={`Button__${variant} ${className} ${
          fullWidth ? "Button--fullWidth" : ""
        } Button--${size ? size : "regular"}`}
        href={link}
        onClick={() => scrollTo(`${scrollTarget}`)}
      >
        {children ? children : text}
      </a>
    </>
  );
};

export default Button;
